import loadable from "@/utils/loadable.js";

const SaleApply = loadable(() => import("@/page/Sale/SaleApply"));
const SaleArrivalNotice = loadable(() =>
  import("@/page/Sale/SaleArrivalNotice")
);
const SalePoundList = loadable(() => import("@/page/Sale/SalePoundList"));
//销售计划
const SalePlan = loadable(() => import("@/page/Sale/SalePlan"));
//采购计划
const BuyPlan = loadable(() => import("@/page/Buy/BuyPlan"));
const buyApply = loadable(() => import("@/page/Buy/buyApply"));
const buyPoundList = loadable(() => import("@/page/Buy/buyPoundList"));
const buySourceSetting = loadable(() =>
  import("@/page/Buy/buySetting/buySourceSetting")
);

const arrivalNotice = loadable(() => import("@/page/Buy/arrivalNotice"));

const weightApproval = loadable(() =>
  import("@/page/manage/businessApproval/weightApproval")
);

const customerBalance = loadable(() =>
  import("@/page/manage/financyManager/customerBalance")
);
const financeDetail = loadable(() =>
  import("@/page/manage/financyManager/financeDetail")
);
const PayOrder = loadable(() =>
  import("@/page/manage/PayOrderManage/PayOrder")
);
const UpstreamFlow = loadable(() =>
  import("@/page/manage/PayOrderManage/UpstreamFlow")
);

const SplitAccount = loadable(() =>
  import("@/page/manage/PayOrderManage/SplitAccount")
);
const buyDetailEachCar = loadable(() =>
  import("@/page/manage/reportFormManage/buyReportForm/buyDetailEachCar")
);
const buySupplierCount = loadable(() =>
  import("@/page/manage/reportFormManage/buyReportForm/buySupplierCount")
);
const saleDetailEachCar = loadable(() =>
  import("@/page/manage/reportFormManage/saleReportForm/saleDetailEachCar")
);
const saleCustomerCount = loadable(() =>
  import("@/page/manage/reportFormManage/saleReportForm/saleCustomerCount")
);
const transportDetailEachCar = loadable(() =>
  import(
    "@/page/manage/reportFormManage/transportReportForm/transportDetailEachCar"
  )
);
const transportMaterial = loadable(() =>
  import("@/page/manage/reportFormManage/transportReportForm/transportMaterial")
);

const userManage = loadable(() => import("@/page/setting/userManage"));
const roleManage = loadable(() => import("@/page/setting/roleManage"));
const loginLog = loadable(() => import("@/page/setting/systemLog/loginLog"));
const replyLog = loadable(() => import("@/page/setting/systemLog/replyLog"));
const noticeLog = loadable(() => import("@/page/setting/systemLog/noticeLog"));
// const noteLog = loadable(() => import('@/page/setting/systemLog/noteLog'));
const otherOptionLog = loadable(() =>
  import("@/page/setting/systemLog/otherOptionLog")
);
const poundLog = loadable(() => import("@/page/setting/systemLog/poundLog"));
const processSet = loadable(() => import("@/page/setting/processSetting"));

const FileCustomer = loadable(() => import("@/page/File/Customer"));
const FileSupplier = loadable(() => import("@/page/File/Supplier"));
const FileCarManageList = loadable(() =>
  import("@/page/File/CarManage/CarManageList")
);
const FileCarBlackList = loadable(() =>
  import("@/page/File/CarManage/CarBlackList")
);
const FileDriver = loadable(() => import("@/page/File/Driver"));
const FileMaterial = loadable(() => import("@/page/File/Material"));
const CustomPrice = loadable(() => import("@/page/File/CustomPrice"));
const FileYard = loadable(() => import("@/page/File/Yard"));

const Weighbridge = loadable(() => import("@/page/File/Weighbridge"));

const OtherInTransportNotice = loadable(() =>
  import("@/page/Other/InTransportNotice")
);
const OtherInTransportPoundList = loadable(() =>
  import("@/page/Other/InTransportPoundList")
);
/* 后台管理*/
//内部用户
const InternalUer = loadable(() =>
  import("@/page/BackManagement/internalManage/InternalUer")
);
//角色管理
const RoleManage = loadable(() =>
  import("@/page/BackManagement/internalManage/RoleManage")
);
//菜单管理
const MenuManage = loadable(() =>
  import("@/page/BackManagement/internalManage/MenuManage")
);
//菜单功能管理
const MenuFunManage = loadable(() =>
  import("@/page/BackManagement/internalManage/MenuFunManage")
);
//权限管理
const AuthManage = loadable(() =>
  import("@/page/BackManagement/internalManage/AuthManage")
);

//外部厂区
const ExternalFactory = loadable(() =>
  import("@/page/BackManagement/ExternalFactory")
);
//厂区功能设置
const PlantFunctionSetting = loadable(() =>
  import("@/page/BackManagement/PlantFunctionSetting")
);
//分账设置

const DimensionSetting = loadable(() =>
  import("@/page/BackManagement/DimensionSetting")
);
//销售组织
const SalesOrganization = loadable(() =>
  import("@/page/BackManagement/SalesOrganization")
);
//采购组织
const PurchaseOrgManage = loadable(() =>
  import("@/page/BackManagement/PurchaseOrgManage")
);
//支付报表
const paymentreportsub = loadable(() =>
  import("@/page/manage/reportFormManage/paymentreport/paymentreportsub")
);
const menuIconUrls = [
  "assets/icon/menu-sale.png",
  "assets/icon/menu-buy.png",
  "assets/icon/menu-other.png",
  "assets/icon/menu-manage.png",
  "assets/icon/menu-file.png",
  "assets/icon/menu-setting.png",
];

export default [
  {
    key: "Sales_business",
    label: "销售业务",
    icon: menuIconUrls[0],
    children: [
      {
        key: "sale_plan",
        label: "销售计划",
        path: "/sale_plan",
        exact: true,
        render: SalePlan,
      },
      {
        key: "Sales_requisition",
        label: "销售申请单",
        path: "/Sales_requisition",
        exact: true,
        render: SaleApply,
      },
      {
        key: "Delivery_notice",
        label: "提货通知单",
        path: "/Delivery_notice",
        exact: true,
        render: SaleArrivalNotice,
      },
      {
        key: "Sales_pound_list",
        label: "销售磅单",
        path: "/Sales_pound_list",
        exact: true,
        render: SalePoundList,
      },
    ],
  },
  {
    key: "Purchase_business",
    label: "采购业务",
    icon: menuIconUrls[1],
    children: [
      {
        key: "purchase_plan",
        label: "采购计划",
        path: "/purchase_plan",
        exact: true,
        render: BuyPlan,
      },
      {
        key: "Purchase_requisition",
        label: "采购申请单",
        path: "/Purchase_requisition",
        exact: true,
        render: buyApply,
      },
      {
        key: "Arrival_notice",
        label: "到货通知单",
        path: "/Arrival_notice",
        exact: true,
        render: arrivalNotice,
      },
      {
        key: "Purchase_order",
        label: "采购磅单",
        path: "/Purchase_order",
        exact: true,
        render: buyPoundList,
      },
      {
        key: "Purchase_settings",
        label: "采购设置",
        children: [
          {
            key: "Purchase_original_shipment_settings",
            label: "采购原发设置",
            path: "/Purchase_original_shipment_settings",
            exact: true,
            render: buySourceSetting,
          },
        ],
      },
    ],
  },
  {
    key: "Other_business",
    label: "其他业务",
    icon: menuIconUrls[2],
    children: [
      {
        key: "In_plant_transportation_notice",
        label: "厂内倒运通知单",
        path: "/In_plant_transportation_notice",
        exact: true,
        render: OtherInTransportNotice,
      },
      {
        key: "In_plant_transshipment_list",
        label: "厂内倒运磅单",
        path: "/In_plant_transshipment_list",
        exact: true,
        render: OtherInTransportPoundList,
      },
    ],
  },
  {
    key: "Business_management",
    label: "业务管理",
    icon: menuIconUrls[3],
    children: [
      {
        key: "Business_approval",
        label: "业务审批",
        children: [
          {
            key: "Weighing_approval",
            label: "过磅审批",
            path: "/Weighing_approval",
            exact: true,
            render: weightApproval,
          },
        ],
      },
      {
        key: "financial_management",
        label: "财务管理",
        children: [
          {
            key: "Customer_balance",
            label: "客户余额",
            path: "/Customer_balance",
            exact: true,
            render: customerBalance,
          },
          {
            key: "Fund_details",
            label: "资金明细",
            path: "/Fund_details",
            exact: true,
            render: financeDetail,
          },
        ],
      },
      {
        key: "Payment_management",
        label: "支付订单管理",
        children: [
          {
            key: "Payment_order_query",
            label: "支付订单查询",
            path: "/Payment_order_query",
            exact: true,
            render: PayOrder,
          },
          {
            key: "Upstream_flow_query",
            label: "上游流水查询",
            path: "/Upstream_flow_query",
            exact: true,
            render: UpstreamFlow,
          },
          {
            key: "Journal_query",
            label: "分账流水查询",
            path: "/Journal_query",
            exact: true,
            render: SplitAccount,
          },
        ],
      },
      {
        key: "Report_management",
        label: "报表管理",
        children: [
          {
            key: "Purchase_report",
            label: "采购报表",
            children: [
              {
                key: "Purchase_vehicle_by_vehicle_details",
                label: "采购逐车明细",
                path: "/Purchase_vehicle_by_vehicle_details",
                exact: true,
                render: buyDetailEachCar,
              },
              {
                key: "Purchase_supplier_statistics",
                label: "采购供应商统计",
                path: "/Purchase_supplier_statistics",
                exact: true,
                render: buySupplierCount,
              },
            ],
          },
          {
            key: "Sales_Report",
            label: "销售报表",
            children: [
              {
                key: "Sales_vehicle_by_vehicle_details",
                label: "销售逐车明细",
                path: "/Sales_vehicle_by_vehicle_details",
                exact: true,
                render: saleDetailEachCar,
              },
              {
                key: "Sales_customer_statistics",
                label: "销售客户统计",
                path: "/Sales_customer_statistics",
                exact: true,
                render: saleCustomerCount,
              },
            ],
          },
          {
            key: "Reshipment_Report",
            label: "倒运报表",
            children: [
              {
                key: "Details_of_transportation_vehicle_by_vehicle",
                label: "倒运逐车明细",
                path: "/Details_of_transportation_vehicle_by_vehicle",
                exact: true,
                render: transportDetailEachCar,
              },
              {
                key: "Plant_statistics",
                label: "倒运物料调出堆场统计",
                path: "/Plant_statistics",
                exact: true,
                render: transportMaterial,
              },
            ],
          },
          {
            key: "paymentreport",
            label: "支付报表",
            children: [
              {
                key: "paymentreportsub",
                label: "支付报表",
                path: "/paymentreportsub",
                exact: true,
                render: paymentreportsub,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "file_management",
    label: "档案管理",
    icon: menuIconUrls[4],
    children: [
      {
        key: "Customer_profile",
        label: "客户档案",
        path: "/Customer_profile",
        exact: true,
        render: FileCustomer,
      },
      {
        key: "Supplier_file",
        label: "供应商档案",
        path: "/Supplier_file",
        exact: true,
        render: FileSupplier,
      },
      {
        key: "Vehicle_management",
        label: "车辆管理",
        children: [
          {
            key: "car_management",
            label: "车辆管理",
            path: "/car_management",
            exact: true,
            render: FileCarManageList,
          },
          {
            key: "Vehicle_blacklist",
            label: "车辆黑名单",
            path: "/Vehicle_blacklist",
            exact: true,
            render: FileCarBlackList,
          },
        ],
      },
      {
        key: "Driver_management",
        label: "司机管理",
        path: "/Driver_management",
        exact: true,
        render: FileDriver,
      },
      {
        key: "material_management",
        label: "物料管理",
        path: "/material_management",
        exact: true,
        render: FileMaterial,
      },
      {
        key: "yard_management",
        label: "堆场管理",
        path: "/yard_management",
        exact: true,
        render: FileYard,
      },
      {
        key: "Customer_material_price_management",
        label: "客户物料价格管理",
        path: "/Customer_material_price_management",
        exact: true,
        render: CustomPrice,
      },
      {
        key: "loadometer_management",
        label: "地磅管理",
        path: "/loadometer_management",
        exact: true,
        render: Weighbridge,
      },
    ],
  },
  {
    key: "System_settings",
    label: "系统设置",
    icon: menuIconUrls[5],
    children: [
      {
        key: "Plant_user_management",
        label: "用户管理",
        path: "/Plant_user_management",
        exact: true,
        render: userManage,
      },
      {
        key: "Role_management",
        label: "角色管理",
        path: "/Role_management",
        exact: true,
        render: roleManage,
      },
      {
        key: "systemLog",
        label: "系统日志",
        children: [
          {
            key: "loginLog",
            label: "登录日志",
            path: "/loginLog",
            exact: true,
            render: loginLog,
          },
          {
            key: "replyLog",
            label: "申请单日志",
            path: "/replyLog",
            exact: true,
            render: replyLog,
          },
          {
            key: "noticeLog",
            label: "通知单日志",
            path: "/noticeLog",
            exact: true,
            render: noticeLog,
          },
          // {
          //   key: 'noteLog',
          //   label: '短信日志',
          //   path: '/noteLog',
          //   exact: true,
          //   render: noteLog,
          // },
          {
            key: "otherOptionLog",
            label: "其他操作日志",
            path: "/otherOptionLog",
            exact: true,
            render: otherOptionLog,
          },
          {
            key: "poundLog",
            label: "磅单日志",
            path: "/poundLog",
            exact: true,
            render: poundLog,
          },
        ],
      },
      {
        key: "Main_process_settings",
        label: "主流程设置",
        path: "/Main_process_settings",
        exact: true,
        render: processSet,
      },
      {
        key: "back_management",
        label: "后台管理",
        children: [
          {
            key: "internalManage",
            label: "内部用户管理",
            children: [
              {
                key: "userManage",
                label: "用户管理",
                path: "/userManage",
                exact: true,
                render: InternalUer,
              },
              {
                key: "roleManage",
                label: "角色管理",
                path: "/roleManage",
                exact: true,
                render: RoleManage,
              },
              {
                key: "menuManage",
                label: "菜单管理",
                path: "/menuManage",
                exact: true,
                render: MenuManage,
              },
              {
                key: "menuFunManage",
                label: "菜单功能管理",
                path: "/menuFunManage",
                exact: true,
                render: MenuFunManage,
              },
              {
                key: "authManage",
                label: "权限管理",
                path: "/authManage",
                exact: true,
                render: AuthManage,
              },
            ],
          },
          {
            key: "External_plant_management",
            label: "外部厂区管理",
            path: "/External_plant_management",
            exact: true,
            render: ExternalFactory,
          },
          {
            key: "Sales_organization_management",
            label: "销售组织管理",
            path: "/Sales_organization_management",
            exact: true,
            render: SalesOrganization,
          },
          {
            key: "Plant_function_setting",
            label: "厂区功能设置",
            path: "/Plant_function_setting",
            exact: true,
            render: PlantFunctionSetting,
          },
          {
            key: "DimensionSetting",
            label: "分账功能设置",
            path: "/Dimension_setting",
            exact: true,
            render: DimensionSetting,
          },
          {
            key: "purchase_organization_management",
            label: "采购组织管理",
            path: "/purchase_organization_management",
            exact: true,
            render: PurchaseOrgManage,
          },
        ],
      },
    ],
  },
];
