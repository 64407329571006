import React from 'react';
import loadable from '@/utils/loadable.js';

import Index from '@/page/index.js';
import routersConfig from './config';
import { Redirect } from 'react-router-dom';
const Login = loadable(() => import('@/page/Login'));
const Home = loadable(() => import('@/page/Home'));
const notFound = loadable(() => import('@/page/NotFound'));
const AddData = loadable(() => import('@/page/AddData'));

const routers = [
  {
    key: 'login',
    label: '登录',
    path: '/login',
    exact: true,
    render: Login,
  },
  {
    key: 'addData',
    label: '新增数据',
    path: '/addData',
    exact: true,
    render: AddData,
  },
  {
    key: '404',
    path: '/404',
    exact: true,
    render: notFound,
  },
  {
    key: 'index',
    path: '/',
    render: Index,
    arr: [
      {
        key: 'home',
        label: '首页',
        path: '/home',
        render: Home,
        exact: true,
      },
    ],
  },
];
function pushRoute(routersArr) {
  routersArr.forEach(item => {
    if (item.exact) {
      routers[routers.length - 1].arr.push({
        key: item.key,
        label: item.label,
        path: item.path,
        exact: true,
        render: item.render,
      });
    } else if (item.children && item.children.length) {
      pushRoute(item.children);
    }
  });
}
pushRoute(routersConfig);
routers[routers.length - 1].arr.push(
  {
    key: 'home',
    label: 'home',
    path: '/',
    exact: true,
    render: () => <Redirect to="/home" />,
  },
  {
    key: 'notFound',
    label: '404',
    path: '/*',
    exact: true,
    render: () => <Redirect to="/404" />,
  }
);

export default routers;
