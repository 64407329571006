import React from 'react';
import { Route } from 'react-router-dom';
import routers from './routers';

const routeView = path => {
  let arr = path ? routers.filter(e => e.key === path)[0].arr : routers;
  return arr.map(route => {
    if (route.path && route.render) {
      return (
        <Route
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.render}
        />
      );
    }
    return null;
  });
};

export { routers, routeView };
