import axios from 'axios';
import portList from './api';
import { message } from 'antd';
import qs from 'qs';

let overdue = false;
const service = axios.create({
  timeout: 50000,
  async: true,
  crossDomain: true,
  withCredentials: true,
});

const tokenError = () => {
  if (!overdue) {
    overdue = true;
    window.location.href = '#/login';
  }
};

if (!sessionStorage.getItem('accessToken')) tokenError();

service.interceptors.request.use(function (config) {
  let token =
    (JSON.parse(sessionStorage.getItem('accessToken')) &&
      JSON.parse(sessionStorage.getItem('accessToken'))['access_token']) ||
    '';

  if (token) {
    config.headers['access-token'] = token;
  }
  if (config.Authorization) {
    config.headers['Authorization'] = config.Authorization;
  }
  if (config['Content-Type']) {
    config.headers['Content-Type'] = config['Content-Type'];
  }
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    };
  }
  return config;
});
service.interceptors.response.use(
  function (response) {
    overdue = false;
    let status = response.status;
    if (status === 200) {
      if (response.config && response.config.responseType === 'blob')
        return response.data || false;
      else if (response.data && response.data.code == 200)
        return response.data.result || response.data;
      //eslint-disable-line
      // 如果是导出请求，下载
      else if (
        response.headers['transfer-encoding'] &&
        response.headers['transfer-encoding'] === 'chunked'
      ) {
        window.open(response.request.responseURL);
        return;
      }
      if (response.data && response.data.code == 403) {
        // window.location.href = '#/login';
        return false;
      }
      const errMsg =
        (response.data &&
          response.data.message &&
          response.data.message.message) ||
        '服务器异常';
      message.error(errMsg);

      return false;
    } else {
      console.log('有可能是网络问题 ！');
      return false;
    }
  },
  error => {
    let err = error.request;
    if (
      err.status === 401 ||
      err.status === 403 ||
      (error.response &&
        (error.response.status === 401 || error.response.status === 403))
    )
      tokenError();
    else if (err.status === 404) err.message = '接口不存在';
    erroInfo(err);
    return Promise.reject(error);
  }
);
let API = {};
function run(note) {
  let type = note.type || '';
  let module = note.module || portList.module;
  let gate = note.gate || portList.gate;
  let responseType = note.responseType || 'json';
  let requestType = note.requestType || '';

  let method = (note.method || 'post').toLocaleLowerCase();
  let url = '';
  if (gate) url += '/' + gate;
  if (module) url += '/' + module;
  url += note.url;
  return function (data) {
    let config = {
      url,
      method,
      responseType,
      requestType,
    };
    if (type) {
      config[type] = data;
    } else {
      if (method === 'get') {
        config.params = data;
      } else if (requestType == 'query') {
        config.params = data;
      } else {
        config.data = data;
        if (note.ContentType) {
          config['Content-Type'] = note.ContentType;
        }
        if (note.Authorization) {
          config['Authorization'] = note.Authorization;
        }
      }
    }
    return new Promise((resolve, reject) => {
      service(config).then(
        res => {
          resolve(res);
        },
        err => {
          // message.error('网络异常');
          reject(err);
        }
      );
    });
  };
}
for (const key in portList.list) {
  API[key] = {};
  for (const l in portList.list[key]) {
    API[key][l] = run(portList.list[key][l]);
  }
}

let timer = null;
const erroInfo = error => {
  if (timer) return;
  message.error(
    error.message || (error.response ? error.response.message : '网络异常')
  );
  timer = setTimeout(_ => {
    timer = null;
  }, 500);
};
global.API = API;
