import React, { Component } from 'react';
import { CancelButton, OkButton } from '@/components/Buttons';
import './index.scss';
import { Modal, Spin, Icon } from 'antd';
import { getDataType } from '@/utils/tool.js';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
function renderCloseIcon(that) {
  if (that.props.closable === false) return <></>;
  return (
    <span className="bfb-modal-close" onClick={that.hideModal}>
      ×
    </span>
  );
}
function renderTitle(that) {
  if (typeof that.props.title === 'string' && that.props.title)
    return (
      <div className="bfb-modal-title">
        <span className="modalTitleTxt">{that.props.title}</span>
        <div
          style={{
            marginLeft: '20px',
            position: 'absolute',
            display: 'inline-block',
          }}
        >
          <Spin
            spinning={that.state.loading}
            indicator={antIcon}
            size="small"
          ></Spin>
        </div>
      </div>
    );
  return that.props.title;
}
function renderFooter(that) {
  if (that.props.footer) return that.props.footer;
  if (that.props.ViewModal || that.props.viewModal) {
    return (
      <div className="bfb-modal-footer">
        <CancelButton text="关闭" onClick={that.hideModal} />
      </div>
    );
  }
  if (that.props.footerReverse) {
    return (
      <div className="bfb-modal-footer">
        <OkButton
          loading={that.state.loading}
          disabled={that.props.okDisabled}
          text={that.props.okText}
          onClick={that.onOk}
        />
        <CancelButton text={that.props.cancelText} onClick={that.hideModal} />
      </div>
    );
  }
  return (
    <div className="bfb-modal-footer">
      <CancelButton text={that.props.cancelText} onClick={that.hideModal} />
      <OkButton
        loading={that.state.loading}
        disabled={that.props.okDisabled}
        text={that.props.okText}
        onClick={that.onOk}
      />
    </div>
  );
}
function getValidateResByLoop(children, inputsNode) {
  let res = [[], {}];
  let keyIndex = 0;
  function handleInput(input) {
    keyIndex++;
    if (input.ref.current.validate && !input.ref.current.validate())
      res[0].push(input.key || 'key_' + keyIndex);
    res[1][input.key || 'key_' + keyIndex] = input.ref.current.getValue();
    inputsNode[input.key || 'key_' + keyIndex] = input.ref.current;
  }
  function loop(dom) {
    if (getDataType(dom) === 'Object') {
      if (dom.props.isInput && dom.ref.current) handleInput(dom);
      else loop(dom.props.children);
    } else if (getDataType(dom) === 'Array') {
      for (let item of dom) {
        loop(item);
      }
    }
  }
  if (!inputsNode) {
    inputsNode = {};
    loop(children);
  } else {
    for (let inputKey in inputsNode) {
      if (inputsNode[inputKey].validate && !inputsNode[inputKey].validate())
        res[0].push(inputKey);
      res[1][inputKey] = inputsNode[inputKey].getValue();
    }
  }

  return res;
}
function getValidateResByProp(children, inputsNode) {
  let res = [[], {}];
  if (!inputsNode) {
    inputsNode = {};
    for (let item of children) {
      inputsNode[item.current?.props?.keys] = item.current;
      if (item.current?.validate && !item.current.validate())
        res[0].push(item.current.props.keys);
      res[1][item.current?.props?.keys] = item?.current?.getValue();
    }
  } else {
    for (let inputKey in inputsNode) {
      if (inputsNode[inputKey].validate && !inputsNode[inputKey].validate())
        res[0].push(inputKey);
      res[1][inputKey] = inputsNode[inputKey].getValue();
    }
  }

  return res;
}
function getValueResByLoop(children, inputsNode) {
  let res = {};
  let keyIndex = 0;
  function handleInput(input) {
    keyIndex++;
    res[input.key || 'key_' + keyIndex] = input.ref.current.getValue();
    inputsNode[input.key || 'key_' + keyIndex] = input.ref.current;
  }
  function loop(dom) {
    if (getDataType(dom) === 'Object') {
      if (dom.props.isInput && dom.ref.current) handleInput(dom);
      else loop(dom.props.children);
    } else if (getDataType(dom) === 'Array') {
      for (let item of dom) {
        loop(item);
      }
    }
  }
  if (!inputsNode) {
    inputsNode = {};
    loop(children);
  } else {
    for (let inputKey in inputsNode) {
      res[inputKey] = inputsNode[inputKey].getValue();
    }
  }
  return res;
}
function getValueResByProp(children, inputsNode) {
  let res = {};
  if (!inputsNode) {
    inputsNode = {};
    for (let item of children) {
      inputsNode[item?.current?.props?.keys] = item.current;
      res[item.current?.props?.keys] = item?.current?.getValue();
    }
  } else {
    for (let inputKey in inputsNode) {
      res[inputKey] = inputsNode[inputKey].getValue();
    }
  }

  return res;
}
function setValueByLoop(children, objVal, inputsNode) {
  let keyIndex = 0;
  function handleInput(input) {
    keyIndex++;
    inputsNode[input.key || 'key_' + keyIndex] = input.ref.current;
  }
  function loop(dom) {
    if (getDataType(dom) === 'Object') {
      if (dom.props.isInput && dom.ref.current) handleInput(dom);
      else loop(dom.props.children);
    } else if (getDataType(dom) === 'Array') {
      for (let item of dom) {
        loop(item);
      }
    }
  }
  if (!inputsNode) {
    inputsNode = {};
    loop(children);
  }
  for (let inputKey in objVal) {
    if (inputsNode[inputKey]) {
      inputsNode[inputKey].setValue(objVal[inputKey]);
    }
  }
}
function setValueByProp(children, objVal, inputsNode) {
  if (!inputsNode) {
    inputsNode = {};
    for (let item of children) {
      inputsNode[item?.current?.props?.keys] = item.current;
    }
  }
  for (let inputKey in objVal) {
    if (inputsNode[inputKey]) {
      inputsNode[inputKey].setValue(objVal[inputKey]);
    }
  }
}

class index extends Component {
  state = {
    visible: this.props.visible,
    loading: false,
    destroyOnClose:
      this.props.destroyOnClose === undefined
        ? true
        : this.props.destroyOnClose,
    forceRender:
      this.props.forceRender === undefined ? false : this.props.forceRender,
    footerReverse: this.props.footerReverse, // 取消和确定的位置交换
  };
  inputs = null; //缓存input node
  componentDidMount() {}
  showModal = callback => {
    this.setState({ visible: true }, () => {
      callback && callback();
    });
  };
  hideModal = () => {
    this.setState({ visible: false });
    this.props.onHide && this.props.onHide();
  };
  visibleModal = (visible = false, callback) =>
    this.setState({ visible }, callback);
  showLoading = () => {
    this.setState({ loading: true });
  };
  hideLoading = () => {
    this.setState({ loading: false });
  };
  validate = () => {
    if (this.props.inputsWrapper) {
      let children = this.props.inputsWrapper;
      return getValidateResByProp(children, this.inputs);
    }
    let children = this.props.inputsWrapper || this.props.children;
    return getValidateResByLoop(children, this.inputs);
  };
  getValue = () => {
    if (this.props.inputsWrapper) {
      let children = this.props.inputsWrapper;
      return getValueResByProp(children, this.inputs);
    }
    let children = this.props.inputsWrapper || this.props.children;
    return getValueResByLoop(children, this.inputs);
  };
  setValue = objVal => {
    if (this.props.inputsWrapper) {
      let children = this.props.inputsWrapper;
      return setValueByProp(children, objVal, this.inputs);
    }
    let children = this.props.children;
    return setValueByLoop(children, objVal, this.inputs);
  };
  onOk = () => {
    this.props.onOk && this.props.onOk();
  };
  render() {
    return (
      <Modal
        className="bfb-modal"
        style={Object.assign(
          {
            minWidth: this.props.width || '500px',
          },
          this.props.style
        )}
        width={this.props.width || '500px'}
        maskStyle={{
          backgroundColor: 'rgba(0,27,79,0.4)',
        }}
        footer={null}
        visible={this.state.visible}
        closable={false}
        destroyOnClose={this.state.destroyOnClose}
        forceRender={this.state.forceRender}
        zIndex={this.props.zIndex}
      >
        {renderCloseIcon(this)}
        {renderTitle(this)}
        <div
          className={`bfb-modal-body  ${
            typeof this.props.children === 'string' ? 'bfb-modal-body-str' : ''
          }`}
          style={this.props.bodyStyle}
        >
          {this.props.children}
        </div>
        {renderFooter(this)}
      </Modal>
    );
  }
}

export default index;
