import React, { Component } from 'react';
import { Button } from 'antd';
import './index.scss';
class index extends Component {
  click = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    return (
      <Button
        loading={this.props.loading}
        disabled={this.props.disabled}
        className={this.props.className}
        onClick={this.click}
      >
        {this.props.content}
      </Button>
    );
  }
}

export default index;
